import {
  YStack,
  Image,
  XStack,
  getTokens,
  ImageProps,
  YStackProps,
} from '@bounty/creators-design-system'
import { generateImages } from './ImageRoll.images'
import { useEffect, useState } from 'react'

const videoAspectRatio = 1.72
const spacing: keyof ReturnType<typeof getTokens>['space'] = 6

const useImages = ({
  width: containerWidth,
  height: containerHeight,
  overflowMultiplier,
  numberOfColumns,
}: {
  width: number
  height: number
  overflowMultiplier: number
  numberOfColumns: number
}) => {
  const { space } = getTokens({ prefixed: false })
  const columns = numberOfColumns

  // Rerenders will cause these to jank
  const [images, setImages] = useState(generateImages(columns))

  useEffect(() => {
    setImages(generateImages(columns))
  }, [containerWidth, columns, setImages])

  const spacingValue = space[spacing]['val']
  const columnWidth = containerWidth / columns
  const columnWidthMinusSpacing = columnWidth - spacingValue

  const width = Math.floor(columnWidthMinusSpacing * overflowMultiplier)
  const height = Math.floor(
    columnWidthMinusSpacing * overflowMultiplier * videoAspectRatio,
  )

  return {
    containerWidth,
    columns,
    images,
    upDirectionStartingY: 0,
    downDirectionStartingY:
      -Number((height + spacingValue) * images[0].length - containerHeight) + 0,
    // This doesn't work sometimes, not sure why
    // Make these images start in the middle of adjacent images
    // (height / 2 - spacingValue),
    imageProps: {
      width,
      height,
    },
  }
}

const ImageRoll = ({
  imageProps,
  images,
  animationDirection,
  downDirectionStartingY,
  upDirectionStartingY,
  ...rest
}: YStackProps & {
  animationDirection: 'up' | 'down'
  images: string[]
  imageProps: Partial<ImageProps>
  downDirectionStartingY: number
  upDirectionStartingY: number
}) => {
  return (
    <YStack
      space={`$${spacing}`}
      flexGrow={1}
      position="relative"
      alignSelf="stretch"
      animation="molasses"
      enterStyle={{
        y:
          animationDirection === 'up'
            ? upDirectionStartingY
            : downDirectionStartingY,
      }}
      {...(animationDirection === 'up'
        ? { y: downDirectionStartingY }
        : { y: 0 })}
      {...rest}
    >
      {images.map((image, index) => {
        return (
          // @ts-expect-error - Height and width will be given and aren't necessarily required
          <Image
            key={index}
            src={image}
            borderRadius="$2"
            resizeMode="cover"
            borderWidth={1}
            borderColor={'$neutral.50'}
            {...imageProps}
          />
        )
      })}
    </YStack>
  )
}

export const ImageRolls = ({
  width,
  height,
  overflowMultiplier,
  numberOfColumns,
}: {
  width: number
  height: number
  overflowMultiplier: number
  numberOfColumns: number
}) => {
  const { images, imageProps, downDirectionStartingY, upDirectionStartingY } =
    useImages({ width, height, overflowMultiplier, numberOfColumns })

  return (
    <XStack
      flex={1}
      space="$6"
      // Remount if anything about the window changes so the animation is perfect
      key={downDirectionStartingY + upDirectionStartingY}
    >
      {images.map((images, index) => {
        return (
          <ImageRoll
            key={index}
            animationDirection={index % 2 ? 'down' : 'up'}
            images={images}
            imageProps={imageProps}
            downDirectionStartingY={downDirectionStartingY}
            upDirectionStartingY={upDirectionStartingY}
          />
        )
      })}
    </XStack>
  )
}
