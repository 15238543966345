import {
  InlineLink,
  Paragraph,
  ParagraphProps,
} from '@bounty/creators-design-system'

export const ConsentText = (props: ParagraphProps) => {
  return (
    <Paragraph size="$xs" style={{ fontSize: 10 }} lineHeight={11} {...props}>
      By submitting this form, you agree to receive recurring automated
      promotional and personalized marketing text messages from Bounty at the
      cell number used when signing up. Consent is not a condition of any
      purchase. Msg frequency varies. Msg & data rates may apply. Text STOP to
      cancel. Read full{' '}
      <InlineLink
        href="/creator-terms-of-use"
        size="$xs"
        style={{ fontSize: 10 }}
        lineHeight={11}
        event="Consent Text Creator Terms of Use Clicked"
        {...props}
      >
        Creator Terms of Use
      </InlineLink>{' '}
      & our{' '}
      <InlineLink
        href="/privacy-policy"
        size="$xs"
        style={{ fontSize: 10 }}
        lineHeight={11}
        event="Consent Text Privacy Policy Clicked"
        {...props}
      >
        Privacy Policy
      </InlineLink>
      .
    </Paragraph>
  )
}
