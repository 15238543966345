import { COUNTRIES } from '@bounty/constants'
import {
  H1,
  YStack,
  LinearGradient,
  Paragraph,
  isWeb,
  useWindowDimensions,
  Text,
  Logo,
  EventObject,
  useAnalytics,
} from '@bounty/creators-design-system'
import { createStateContext } from '../factory'
import { z } from 'zod'
import { useFastForm } from '../components/fastForm/useFastForm'
import { useAuth, useAuthDispatch, useAuthState } from '../hooks/useAuth'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { KeyboardAvoidingView, Platform } from 'react-native'
import { ImageRolls } from '../components/ImageRoll/ImageRoll'
import { useSafeAreaInsets } from '../hooks/useSafeAreaInsets/useSafeAreaInsets'
import { ConsentText } from '../components/ConsentText'
import { useInvite } from '../hooks/useInvite/useInvite'
import phone from 'phone'

export const ONE_TIME_CODE_LENGTH = 6

export const signUpPageDefaults = {
  phoneNumber: '',
  phoneNumberCountry: COUNTRIES[0],
  isSignUpMode: true,
}

export const [SignUpPageProvider, useSignUpPage] =
  createStateContext(signUpPageDefaults)

type LoginPhoneNumberFormProps = {
  buttonText?: ReactNode
  event?: EventObject
}

const LoginPhoneNumberForm = ({
  buttonText,
  event,
}: LoginPhoneNumberFormProps) => {
  const { login } = useAuthDispatch()
  const { isLoading } = useAuthState()
  const [{ orderId, email, shopUrl }] = useInvite()
  const [{ phoneNumber, isSignUpMode }, setSignUpPage] = useSignUpPage()
  const { Form, Input, SubmitButton } = useFastForm({
    schema: z.object({
      phoneNumber: z
        .string()
        .refine(
          (value) => {
            const { isValid } = phone(value)
            return isValid
          },
          {
            message: 'Phone number is invalid.',
          },
        )
        .refine(
          (value) => {
            const phoneUS = phone(value, { country: 'US' })
            const phoneCA = phone(value, { country: 'CA' })

            return phoneUS.isValid || phoneCA.isValid
          },
          {
            message:
              'Phone number is invalid. Unfortunately, Bounty is not currently available to users outside of the United States or Canada.',
          },
        )
        .transform((value) => {
          const { phoneNumber } = phone(value)
          return phoneNumber?.substring(2)
        }),
    }),
    defaultValues: {
      phoneNumber,
    },
  })

  return (
    <Form
      onSubmit={(e) => {
        setSignUpPage((x) => ({ ...x, ...e }))
        login({
          phoneNumberCountry: COUNTRIES[0],
          phoneNumber: e.phoneNumber,
          isSignUpMode,
          email,
          orderId,
          shopUrl,
        })
      }}
      data-testid="phoneForm"
      alignItems="stretch"
      width={300}
      space="$4"
    >
      <Input
        name="phoneNumber"
        keyboardType="phone-pad"
        textContentType="telephoneNumber"
        placeholder="Phone Number"
        {...(isWeb && {
          autoComplete: 'tel',
          type: 'tel',
        })}
      />
      <SubmitButton
        isLoading={isLoading}
        data-testid="signUpOrLoginButton"
        event={[
          { eventName: 'Sign Up Button Clicked', isSignUpMode },
          ...(event ? [event] : []),
        ]}
        alignSelf="stretch"
      >
        {buttonText ? buttonText : isSignUpMode ? 'Sign up' : 'Sign in'}
      </SubmitButton>
    </Form>
  )
}

type LoginPhoneVerificationFormProps = {
  event?: EventObject
}

const LoginPhoneVerificationForm = ({
  event,
}: LoginPhoneVerificationFormProps) => {
  const allowAutoSubmitRef = useRef<boolean>(false)
  const [{ phoneNumberCountry, phoneNumber }] = useSignUpPage()
  const { isLoading } = useAuthState()
  const { track } = useAnalytics()
  const { submitPhoneVerificationCode } = useAuthDispatch()
  const {
    Form,
    Input,
    SubmitButton,
    methods: { watch, handleSubmit },
  } = useFastForm({
    schema: z.object({
      verificationCode: z.string().min(6).max(6),
    }),
    defaultValues: {
      verificationCode: '',
    },
  })

  const verificationCode = watch('verificationCode')

  const submitFunction = useCallback(
    (e: { verificationCode: string }) => {
      submitPhoneVerificationCode({
        verificationCode: e.verificationCode,
        phoneNumber,
        phoneNumberCountry,
      })
    },
    [submitPhoneVerificationCode, phoneNumber, phoneNumberCountry],
  )

  useEffect(() => {
    if (
      allowAutoSubmitRef.current === false &&
      verificationCode.length === ONE_TIME_CODE_LENGTH
    ) {
      allowAutoSubmitRef.current = true
      track('Sign Up Verify Code Clicked')
      handleSubmit(submitFunction)()
    }

    // Reset to do another auto submit if the input is cleared out
    if (verificationCode.length === 0) {
      allowAutoSubmitRef.current = false
    }
  }, [verificationCode, handleSubmit, track, submitFunction])

  return (
    <Form
      onSubmit={submitFunction}
      alignItems="stretch"
      data-testid="verificationCodeForm"
      width={300}
      space="$4"
    >
      <Input
        name="verificationCode"
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        autoFocus
        {...(isWeb && {
          autoComplete: 'one-time-code',
          type: 'number',
        })}
      />
      <SubmitButton
        event={[
          { eventName: 'Sign Up Verify Code Clicked' },
          ...(event ? [event] : []),
        ]}
        data-testid="verifyButton"
        alignSelf="stretch"
        isLoading={isLoading}
      >
        Submit Verification Code
      </SubmitButton>
    </Form>
  )
}

const SignUpOrLogin = () => {
  const [{ signUpStep }, { setSignUpStep }] = useAuth()
  const [{ isSignUpMode }, setSignUpPage] = useSignUpPage()

  if (signUpStep === 'phoneVerification') {
    return (
      <Paragraph
        size="$sm"
        tabIndex={0}
        cursor={'pointer'}
        onPress={() => {
          setSignUpStep('login')
        }}
        // @ts-expect-error - Web only prop
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            setSignUpStep('login')
          }
        }}
        focusStyle={{
          outlineWidth: '1',
          outlineStyle: 'solid',
          outlineColor: '$neutral.500',
          borderRadius: '$1',
        }}
      >
        <Text size="$sm" color="$whiteAlpha.800" textDecorationLine="underline">
          Go back
        </Text>
      </Paragraph>
    )
  }

  return (
    <Paragraph
      size="$sm"
      cursor={'pointer'}
      tabIndex={0}
      onPress={() => {
        setSignUpPage((x) => ({ ...x, isSignUpMode: !x.isSignUpMode }))
      }}
      // @ts-expect-error - Web only prop
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          setSignUpPage((x) => ({ ...x, isSignUpMode: !x.isSignUpMode }))
        }
      }}
      focusStyle={{
        outlineWidth: '1',
        outlineStyle: 'solid',
        outlineColor: '$neutral.500',
        borderRadius: '$1',
      }}
    >
      {isSignUpMode ? (
        <Text size="$sm" color="$whiteAlpha.800">
          Already have an account?{' '}
          <Text
            size="$sm"
            color="$whiteAlpha.800"
            textDecorationLine="underline"
          >
            Sign in
          </Text>
          .
        </Text>
      ) : (
        <Text size="$sm" color="$whiteAlpha.800">
          New to Bounty?{' '}
          <Text
            size="$sm"
            color="$whiteAlpha.800"
            textDecorationLine="underline"
          >
            Sign up
          </Text>
          .
        </Text>
      )}
    </Paragraph>
  )
}

const getNumberOfColumns = (width: number) => {
  if (width < 500) return 3
  if (width < 800) return 4
  if (width < 1000) return 5

  return 8
}

const SignUpImageRoll = () => {
  const { width, height } = useWindowDimensions()

  return (
    <ImageRolls
      width={width}
      height={height}
      overflowMultiplier={1.5}
      numberOfColumns={getNumberOfColumns(width)}
    />
  )
}

export type AuthBaseProps = {
  showLogo?: boolean
  logo?: ReactNode
  showSignUpLogin?: boolean
  title?: ReactNode
  description?: ReactNode
  isSignUpMode?: boolean
  loginPhoneFormProps?: LoginPhoneNumberFormProps
  gradientHeight?: number
  loginPhoneNumberEvent?: LoginPhoneNumberFormProps['event']
  loginPhoneVerifyEvent?: LoginPhoneVerificationFormProps['event']
  colorMode?: 'light' | 'dark'
}

/**
 * A base page for anything that required authentication.
 */
export function AuthBase({
  showLogo = true,
  logo,
  title,
  description,
  isSignUpMode = true,
  showSignUpLogin = true,
  loginPhoneFormProps,
  gradientHeight,
  loginPhoneNumberEvent,
  loginPhoneVerifyEvent,
  colorMode = 'dark',
}: AuthBaseProps) {
  const [{ signUpStep, authErrorComponent }] = useAuth()
  const insets = useSafeAreaInsets()

  /**
   * This page functions as a full page modal. We lock scroll because the native still renders for the page
   * even though the authed contents renders null.
   */
  useEffect(() => {
    if (isWeb) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      if (isWeb) {
        document.body.style.overflow = ''
      }
    }
  }, [])

  return (
    <SignUpPageProvider
      initialValue={{
        ...signUpPageDefaults,
        isSignUpMode,
      }}
    >
      {/*
          // @ts-expect-error - web only css property */}
      <YStack
        // Hack to always make sign up above the nav on web
        {...(isWeb && {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          height: '100vh',
        })}
        f={1}
      >
        <YStack
          f={1}
          overflow="hidden"
          jc="flex-end"
          ai="center"
          p="$4"
          position="relative"
          paddingTop={insets.top}
          paddingBottom={insets.bottom}
          backgroundColor="$primary.900"
          {...(isWeb && { paddingBottom: '$8' })}
        >
          <SignUpImageRoll />
          {colorMode === 'dark' ? (
            <LinearGradient
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              colors={['rgba(16, 24, 40, 0)', '#101828']}
              // Nudge the gradient up the screen a little more if there is a description given
              locations={[
                0,
                gradientHeight ? gradientHeight : description ? 0.55 : 0.62,
              ]}
            />
          ) : (
            <LinearGradient
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              colors={['rgba(251, 252, 252, 0)', '#FBFCFC']}
              // Nudge the gradient up the screen a little more if there is a description given
              locations={[
                0,
                gradientHeight ? gradientHeight : description ? 0.55 : 0.5,
              ]}
            />
          )}
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          >
            <YStack ai="center" space="$4" maw={600}>
              {showLogo && logo ? (
                logo
              ) : (
                <Logo
                  size={132}
                  color={colorMode === 'dark' ? '$neutral.50' : '$neutral.900'}
                />
              )}
              <H1
                ta="center"
                size="$2xl"
                marginTop="$3"
                color={colorMode === 'dark' ? '$neutral.50' : '$neutral.900'}
              >
                {title || 'Get paid to post about your favorite brands'}
              </H1>
              {description && (
                <Paragraph
                  textAlign="center"
                  color={colorMode === 'dark' ? '$neutral.50' : '$neutral.900'}
                >
                  {description}
                </Paragraph>
              )}

              {signUpStep === 'login' && (
                <LoginPhoneNumberForm
                  event={loginPhoneNumberEvent}
                  {...loginPhoneFormProps}
                />
              )}
              {signUpStep === 'phoneVerification' && (
                <LoginPhoneVerificationForm event={loginPhoneVerifyEvent} />
              )}

              {authErrorComponent}
              {showSignUpLogin && <SignUpOrLogin />}
              <ConsentText
                textAlign="center"
                color={
                  colorMode === 'dark' ? '$whiteAlpha.600' : '$blackAlpha.600'
                }
              />
            </YStack>
          </KeyboardAvoidingView>
        </YStack>
      </YStack>
    </SignUpPageProvider>
  )
}
