import Image01 from '../../assets/video_id_7177231989257325870.jpg'
import Image02 from '../../assets/video_id_7103928003352120619.jpg'
import Image03 from '../../assets/video_id_7110377527578725675.jpg'
import Image04 from '../../assets/video_id_7136967940838116651.jpg'
import Image05 from '../../assets/video_id_7077787598084902190.jpg'
import Image06 from '../../assets/video_id_7104712733639232814.jpg'
import Image07 from '../../assets/video_id_7102467719886425386.jpg'
import Image08 from '../../assets/video_id_7106684955370999086.jpg'
import Image09 from '../../assets/video_id_7104369752495230250.jpg'
import Image10 from '../../assets/video_id_7134026786559561006.jpg'
import Image11 from '../../assets/video_id_7132941291100343598.jpg'
import Image12 from '../../assets/video_id_7133331879364676910.jpg'
import Image13 from '../../assets/video_id_7111141381036330286.jpg'
import Image14 from '../../assets/video_id_7110539973685759275.jpg'
import Image15 from '../../assets/video_id_7116394628101786923.jpg'
import Image16 from '../../assets/video_id_7124390940063698222.jpg'
import Image17 from '../../assets/video_id_7127445808269790510.jpg'
import Image18 from '../../assets/video_id_7078011788704615726.jpg'
import Image19 from '../../assets/video_id_7138520141607734574.jpg'
import Image20 from '../../assets/video_id_7094055494230330670.jpg'
import Image21 from '../../assets/video_id_7096957136432958766.jpg'
import Image22 from '../../assets/video_id_7103210750990224683.jpg'
import Image23 from '../../assets/video_id_7100715079414336810.jpg'
import Image24 from '../../assets/video_id_7117371305002978606.jpg'
import Image25 from '../../assets/video_id_7113318115609627950.jpg'
import Image26 from '../../assets/video_id_7114739889132047662.jpg'
import Image27 from '../../assets/video_id_7122877999275494702.jpg'
import Image28 from '../../assets/video_id_7124379008812338474.jpg'
import Image29 from '../../assets/video_id_7137396344695606571.jpg'
import Image30 from '../../assets/video_id_7185387594069462314.jpg'
import Image31 from '../../assets/video_id_7186063967695949102.jpg'
import Image32 from '../../assets/video_id_7185697470670966062.jpg'
import Image33 from '../../assets/video_id_7180451533455363374.jpg'
import Image34 from '../../assets/video_id_7151489451510779178.jpg'
import Image35 from '../../assets/video_id_7178877810029694251.jpg'
import Image36 from '../../assets/video_id_7184325903856520490.jpg'
import Image37 from '../../assets/video_id_7186443111818890542.jpg'
import Image38 from '../../assets/video_id_7181152018117987626.jpg'
import Image39 from '../../assets/video_id_7172212795373669674.jpg'
import Image40 from '../../assets/video_id_7171113427740527878.jpg'
import Image41 from '../../assets/video_id_7183824747527015723.jpg'
import Image42 from '../../assets/video_id_7138183995669368106.jpg'
import Image43 from '../../assets/video_id_7166760575450565930.jpg'
import Image44 from '../../assets/video_id_7172318367292853547.jpg'
import Image45 from '../../assets/video_id_7170165246424861994.jpg'
import Image46 from '../../assets/video_id_7177092177082715438.jpg'
import Image47 from '../../assets/video_id_7169209100465917230.jpg'
import Image48 from '../../assets/video_id_7114293937992420650.jpg'
import Image49 from '../../assets/video_id_7118943592948731179.jpg'
import Image50 from '../../assets/video_id_7129420721880698114.jpg'
import Image51 from '../../assets/video_id_7140326784838470958.jpg'
import Image52 from '../../assets/video_id_7179318074703678762.jpg'

const rawImages = [
  Image01,
  Image02,
  Image03,
  Image04,
  Image05,
  Image06,
  Image07,
  Image08,
  Image09,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  Image21,
  Image22,
  Image23,
  Image24,
  Image25,
  Image26,
  Image27,
  Image28,
  Image29,
  Image30,
  Image31,
  Image32,
  Image33,
  Image34,
  Image35,
  Image36,
  Image37,
  Image38,
  Image39,
  Image40,
  Image41,
  Image42,
  Image43,
  Image44,
  Image45,
  Image46,
  Image47,
  Image48,
  Image49,
  Image50,
  Image51,
  Image52,
]

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffle(oldArray: any[]) {
  const array = [...oldArray]

  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

// https://stackoverflow.com/questions/8188548/splitting-a-js-array-into-n-arrays
function chunkArray(oldArray: any[], chunkNumber: number) {
  const array = [...oldArray]

  const result = []
  for (let i = chunkNumber; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)))
  }
  return result
}

function sameSizeImages(images: any[][]) {
  // Last array will always have the least numbers to even up the other arrays due
  // to how chunking works
  const lastImageArrayLength = images[images.length - 1].length

  return images.map((x) => x.slice(0, lastImageArrayLength))
}

export const generateImages = (chunkSize: number) => {
  return sameSizeImages(chunkArray(shuffle(rawImages), chunkSize))
}
